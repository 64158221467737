import React, { useState, useEffect, useRef, useContext } from 'react'
import { HttpContext } from './context/HttpContext'
import { Cards } from './components/Cards';
import { useHttp } from './hooks/http.hook';
import { CardType } from './components/CardType';
import { PersonalData } from './components/PersonalData';
import { MonetaryValue } from './components/MonetaryValue';
import { DeliveryDetails } from './components/DeliveryDetails';
import { ReceiveOptions } from './components/ReceiveOptions';
import { RemarksBlock } from './components/RemarksBlock';
import { PaymentType } from './components/PaymentType';
import { FinalBlock } from './components/FinalBlock';
import { SubmitButtons } from './components/SubmitButtons';
import { FinalModal } from './components/modals/FinalModal';
import { FinalPage } from './components/FinalPage';

import "react-datepicker/dist/react-datepicker.css"

// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";


function App() {

  const sectionsRef = useRef([])

  const host = useContext(HttpContext)

  const { request } = useHttp()
  const [ vouchers, setVouchers ] = useState([])
  const [ appSettings, setAppSettings ] = useState({
      initialState : true,
      cardType : '', 
      currentVoucherKey : 0, 
      steps : { st1 : true, st2 : true, st3 : false, st4 : false, st5 : false, st6 : false, st7 : false, st8 : false, st9 : false, st10 : false },
      preFade : { st1 : false, st2 : true, st3 : true, st4 : true, st5 : true, st6 : true, st7 : true, st8 : true, st9 : true, st10 : true },
      finalModal : false,
      finalPage : false,
      infoModal : false,
      infoContent : 0,
      deliveryPay : 0,
      toPayDiscount : 0,
      toPay : 0,
      deliveryType : 'pickUp',
      paymentFile : '',
      digitalFile : '',
      digitalFileAdd : '',
      finalSign : '',
      signIsRequired : false,
      showSignNotification : false,
      envelops : '',
      stage3_blocks : [],
      stage5_blocks : [],
      stage9_blocks : [{ title : '', content : '' }, { title : '', content : '' }]
    })


  const getVouchers = async () => {
    try {
        const response = request(`${host.url}/wp-json/api/v1/vouchers`, 'POST', {  }, { 'Content-Type' : 'application/json', 'Authorization' : `Bearer ` }) 
        response.then(data => {
           setVouchers(data.data) 
           setAppSettings({...appSettings, server : data.settings})
        })
      } catch(e) { console.log(e) }
  }

  useEffect(() => {

    getVouchers()

  }, [])

  const [ showBlocks, setShowBlocks ] = useState( true )

  useEffect(() => {
    setShowBlocks( false )

    setTimeout(() => {
      setShowBlocks( true )
    }, 200)

  }, [ appSettings.currentVoucherKey ])


  const scrollTo = (id) => {
    sectionsRef.current[id].scrollIntoView()   
  }

  if(vouchers.length === 0 || appSettings.server === undefined){
    return(
      <div dir="rtl" className="coupons">
        <section className="page-title">
            <div className="wrapper">
                <h1>זה הזמן להעניק את המתנה המושלמת</h1>
            </div>
        </section>
      </div>
    )
  }
  if(appSettings.finalPage) {
    return(
      <FinalPage />
    )
  }
  return (
    <div dir="rtl" className="coupons">
      <section className="page-title">
          <div className="wrapper">
              <h1>זה הזמן להעניק את המתנה המושלמת</h1>
          </div>
      </section>

      <Cards ref={el => sectionsRef.current[0] = el}  vouchers={vouchers} appSettings={appSettings} setAppSettings={setAppSettings} scrollTo={scrollTo}  />
      <CardType ref={el => sectionsRef.current[1] = el} vouchers={vouchers} appSettings={appSettings} setAppSettings={setAppSettings} scrollTo={scrollTo} getVouchers={getVouchers} />
      {showBlocks && 
      <> 
      <PersonalData ref={el => sectionsRef.current[2] = el} vouchers={vouchers} appSettings={appSettings} setAppSettings={setAppSettings} />
      <MonetaryValue ref={el => sectionsRef.current[3] = el} vouchers={vouchers} appSettings={appSettings} setAppSettings={setAppSettings} />
      <DeliveryDetails ref={el => sectionsRef.current[4] = el} vouchers={vouchers} appSettings={appSettings} setAppSettings={setAppSettings} />
      <ReceiveOptions ref={el => sectionsRef.current[5] = el} vouchers={vouchers} appSettings={appSettings} setAppSettings={setAppSettings} />
      <RemarksBlock ref={el => sectionsRef.current[6] = el} vouchers={vouchers} appSettings={appSettings} />
      <PaymentType ref={el => sectionsRef.current[7] = el} vouchers={vouchers} appSettings={appSettings} setAppSettings={setAppSettings} />
      <FinalBlock ref={el => sectionsRef.current[8] = el} vouchers={vouchers} appSettings={appSettings} setAppSettings={setAppSettings} />
      <SubmitButtons ref={el => sectionsRef.current[9] = el} appSettings={appSettings} setAppSettings={setAppSettings} getVouchers={getVouchers} />
      {appSettings.finalModal &&
      <FinalModal appSettings={appSettings} setAppSettings={setAppSettings} />}
      </>}
    </div>
  );
}

export default App
