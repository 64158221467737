import React, { useEffect, useRef, useState, useContext } from 'react'
import { HttpContext } from '../context/HttpContext'

export const MonetaryValue = ({ vouchers, appSettings, setAppSettings }) => {
    
    const [ nominals, setNominals ] = useState([])
    const host = useContext(HttpContext)

    const [envelops, setEnvelops] = useState('-')
    const [currentKey, setCurrentKey] = useState()

    const [minOrder, setMinOrder] = useState()
    const [minOrderD, setMinOrderD] = useState()
    const [maxForPickUp, setMaxForPickUp] = useState()
    const [maxForExpress, setMaxForExpress] = useState()
    const [minForExpress, setMinForExpress] = useState()
    const [deliveryFrom, setDeliveryFrom] = useState()

    const [ minAndMax, setMinAndMax ] = useState({ text : '', min : '', max : '' })

    const [ additionalValue, setAdditionsValue ] = useState('')

    const [showInf, setShowInf] = useState({ first : false, second : false })

    const [ amo, setAmo ] = useState([])

    const [delivery, setDelivery] = useState()
    const [minD, setMinD] = useState()

    const [deliveryTypes, setDeliveryTypes] = useState({
        pickUp : false,
        paid : false,
        free : false
    })

    const inpRef = useRef([])
    const nomRef = useRef([])

    useEffect(() => {
        const key = appSettings.currentVoucherKey
        const type = appSettings.cardType
        if(type === 'plastic') {
            setAmo([])
            setAdditionsValue('')
            // setNominals(vouchers[key].crb_amount_available)
            setNominals([
                { crb_amount_val : 0, quantity : 0, value : 0  },
                { crb_amount_val : 0, quantity : 0, value : 0  },
                { crb_amount_val : 0, quantity : 0, value : 0  },
                { crb_amount_val : 0, quantity : 0, value : 0  },
                { crb_amount_val : 0, quantity : 0, value : 0  }
            ])
           
            setMinAndMax({
                text : vouchers[key].crb_amount_min_max_value,
                min : vouchers[key].crb_amount_min_value,
                max : vouchers[key].crb_amount_max_value
            })

            
        }
        if(type === 'digital') {
            setNominals(vouchers[key].crb_amount_available_d)
        }

        setDelivery(vouchers[key].crb_amount_delivery)
        setMinD(vouchers[key].crb_amount_free_delivery)
        setCurrentKey(key)

    }, [appSettings.currentVoucherKey, appSettings.cardType])

    useEffect(() => {
        let key
        currentKey !== undefined ? key = currentKey : key = 0  
        setMinOrder(vouchers[key].crb_amount_minimal_order_value)
        setMinOrderD(vouchers[key].crb_amount_minimal_order_value_d)
        setMaxForPickUp(vouchers[key].crb_amount_pickup_max_value)
        setMaxForExpress(vouchers[key].crb_express_delivery_max_price)
        setMinForExpress(vouchers[key].crb_express_delivery_min_price)
        setDeliveryFrom(vouchers[key].crb_amount_free_delivery)
    }, [currentKey])

    const [deliveryState, setDeliveryState] = useState({
        pickup : false,
        express : false,
        delivery : false
    })
    
    const styles = {
        display : appSettings.steps.st4 === false && 'none'
    }

    const changeHandler = (operation, i, value) => {
        let currentVal = amo[i].value
        let currentQua = amo[i].quantity

        if(nomRef.current[i].classList.contains('error')){
            return
        }

        switch(operation){
            case 'plus':
                const result = currentVal + parseInt(value)
                const quantity = currentQua + 1
                amo[i].value = result
                amo[i].quantity = quantity
                break
            case 'minus':
                if(currentVal !== 0) {
                    const result = currentVal - parseInt(value)
                    const quantity = currentQua - 1
                    amo[i].value = result
                    amo[i].quantity = quantity
                }
                break
        } 

        let ttp = []
        amo.map(item => {
            ttp.push( item.value )
        })
        let sum=0;
        for(let i=0;i<ttp.length;i++){
            sum = sum + parseInt(ttp[i]);
        }

        sum = sum + additionalValue
        
        let st = appSettings.steps
        let pf = appSettings.preFade
        if(appSettings.cardType === 'digital') {

            if(vouchers[appSettings.currentVoucherKey].crb_amount_4_req_file || vouchers[appSettings.currentVoucherKey].crb_file_additional){

                if(appSettings.digitalFile.length > 0 || appSettings.digitalFileAdd.length > 0) {
                    
                    if(sum >=  parseInt(minOrderD) ){

                        st = {...st, st7 : true, st8 : true, st9: true}
                        pf = {...pf, st7: false, st8 : false}
                       
                    } 
                    
                } 
                
            } else {
                
                if(sum >=  parseInt(minOrderD) ){
                    st = {...st, st7 : true, st8 : true, st9: true}
                    pf = {...pf, st7: false, st8 : false}
                   
                } 
                
            }
            
        } else {

            if(operation === 'plus') {
 
                if(sum >= parseInt(minOrder)) {
                
                    setDeliveryState({...deliveryState, pickup : true})

                }

                if(sum >= parseInt(minForExpress)) {
                    setDeliveryState({...deliveryState, pickup : true, express : true})  
                }

                if(appSettings.currentVoucherKey === 3) {
                    
                    sum > parseInt(deliveryFrom) && setDeliveryState({...deliveryState, delivery : true})

                    sum > parseInt(maxForExpress) &&  setDeliveryState({...deliveryState, express : false, delivery : true})

                    sum > parseInt(maxForExpress) &&  setDeliveryTypes({...deliveryTypes, paid : false })
                    sum > parseInt(maxForPickUp) &&  setDeliveryTypes({...deliveryTypes, pickUp : false })
                } else {
                    
                    sum >= parseInt(deliveryFrom) && setDeliveryState({...deliveryState, delivery : true})

                    sum > parseInt(maxForExpress) &&  setDeliveryState({...deliveryState, express : false})
                    sum > parseInt(maxForPickUp) &&  setDeliveryState({...deliveryState, pickup : false})

                    sum > parseInt(maxForExpress) &&  setDeliveryTypes({...deliveryTypes, paid : false })
                    sum > parseInt(maxForPickUp) &&  setDeliveryTypes({...deliveryTypes, pickUp : false })
                    
                }
                

            }

            if(operation === 'minus') {
                if(sum <= parseInt(maxForPickUp)) { 
                    setDeliveryState({...deliveryState, pickup : true})
                }
                
                if(sum <= parseInt(maxForExpress)) { // was <=
                    setDeliveryState({...deliveryState, express : true})
                }

                if(sum < parseInt(minForExpress)) { // was <=
                    setDeliveryState({...deliveryState, express : false})
                    setDeliveryTypes({...deliveryTypes, paid : false })
                }

                if(appSettings.currentVoucherKey === 3) {
                    
                    if(sum <= parseInt(deliveryFrom)) {
                        setDeliveryState({...deliveryState, delivery : false, express : true})
                        setDeliveryTypes({...deliveryTypes, free : false })
                    }

                } else {
                    if(sum < parseInt(deliveryFrom)) { // was <=
                        setDeliveryState({...deliveryState, delivery : false})
                        setDeliveryTypes({...deliveryTypes, free : false })
                        if(sum >= parseInt(maxForExpress)){
                            setDeliveryState({...deliveryState, express : true})
                        } 
                        if(sum < parseInt(minForExpress)) { // was <=
                            setDeliveryState({...deliveryState, express : false})
                            setDeliveryTypes({...deliveryTypes, paid : false })
                        }
                    }
                }
                
               
                 

                if(sum < parseInt(minOrder)) {
                    
                    setDeliveryState({...deliveryState, pickup : false, express : false, delivery : false})
                    setDeliveryTypes({ pickUp : false, paid : false, free : false })
                }

                if(sum === 0) {
                    setDeliveryState({...deliveryState, pickup : false, express : false, delivery : false})
                    setDeliveryTypes({ pickUp : false, paid : false, free : false })
                }
            }

            st = {...st, st5 : true, st6 : false, st7: false}
            pf = {...pf, st5: true, st8 : true}
            setAppSettings({
                ...appSettings, 
                })
        }
        
        setAppSettings({...appSettings, stage4 : amo, steps : st, preFade : pf, toPay : sum, toPayDiscount : sum - (sum / 100 * appSettings.discount) })
   
    }

    
    useEffect(() => {
        if(window.innerWidth < 1000) {
            if(nomRef.current.length) {
                nomRef.current.map((item, id) => {
                    if(item !== null) {
                        const val = item.querySelector('.input-result').value
                        if(val !== '0 ₪') {
                            item.querySelector('.input-result').style.display = 'block'
                            item.querySelector('.t3').style.display = 'block'
                        } else {
                            item.querySelector('.input-result').style.display = 'none'
                            item.querySelector('.t3').style.display = 'none'
                        }
                    }
                })
            }
        }
        
    }, [appSettings.toPay])

    const handleDeliverySwitch = (sum, operation) => {
        const minOrderValue = parseInt(minOrder)
        const maxPicupValue = parseInt(maxForPickUp)
        const minForExpressValue = parseInt(minForExpress)
        const maxForExpressValue = parseInt(maxForExpress)
        const minForDelivery = parseInt(deliveryFrom)
        

        if(minOrderValue >= sum) {
            setDeliveryState({...deliveryState, pickup : false, express : false, delivery : false})
        } else {
            
        }
        
        
       
    }

    const envelopHandler = (e) => {      
        setEnvelops(parseInt(e.target.value))
        setAppSettings({...appSettings, envelops : e.target.value})
    }

    const emptyEnvelop = () => {
        setEnvelops('')
    }

    const classes = {
        cl : appSettings.preFade.st4 ? 'section-4 pre-fade' : 'section-4'
    }

    useEffect(() => {
        let tmp = []

        let amounts = amo

        nominals.map((item, id) => {
            let val 

            if( amounts[id] === undefined ){
                val = {nominal : item.crb_amount_val, quantity : 0, value : 0}
            } else {
                val = {...amounts[id], nominal : item.crb_amount_val}
            }
    
            // const val = { nominal : item.crb_amount_val, value : item.value, quantity : item.quantity }

            tmp.push(val)

        })

        setAmo( tmp )         
    }, [ nominals ])


    const deliveryTypeHandler = (type, deliveryPrice, e) => {

        if(deliveryPrice === '') {
            deliveryPrice = 0
        }

        let st = appSettings.steps
        let pf = appSettings.preFade
        if(appSettings.cardType === 'plastic') {
            st = {...st, st6 : true}
            pf = {...pf, st5 : false}
        } else {
            st = {...st, st7 : true, st8 : true, st9: true}
            pf = {...pf, st7: false, st8 : false}
        }
        if(appSettings.toPay >= minOrder) {
            switch(type){
                case 'pickUp':
                    if(e.target.classList.contains('hidden-delivery') === false) {
                        st = {...st, st5: false, st7 : true }
                        pf = {...pf, st6: false, st7: true, st8: true  }
                        setDeliveryTypes({ pickUp : true, paid : false, free : false })
                        setAppSettings({...appSettings, deliveryPay : 0, deliveryType : type, steps : st, preFade : pf})
                    }
                    
                    break
                case 'paid':
                    if(e.target.classList.contains('hidden-delivery') === false) {
                        // st = {...st, st5: true, st7 : true, st6 : false }
                        // pf = {...pf, st6: false }
                        st = {...st, st5: false, st6 : false, st7 : true, st8: true, st9: true  }
                        pf = {...pf, st6: false, st7: false, st8: false }
                      
                        setDeliveryTypes({ pickUp : false, paid : true, free : false })
                        setAppSettings({...appSettings, deliveryPay : parseInt(deliveryPrice), deliveryType : type, steps : st, preFade : pf})
                    }
                    
                    break
                case 'free':
                    if(e.target.classList.contains('hidden-delivery') === false) {
                        // st = {...st, st5: true, st7 : true, st6 : false }
                        // pf = {...pf, st6: false }
                        st = {...st, st5: false, st6 : false, st7 : true, st8: true, st9: true  }
                        pf = {...pf, st6: false, st7: false, st8: false }
                       
                        setDeliveryTypes({ pickUp : false, paid : false, free : true })
                        setAppSettings({...appSettings, deliveryPay : parseInt(deliveryPrice), deliveryType : type, steps : st, preFade : pf})
                    }
                    break   
            }
        }  
    }

    const [uplF, setUplF] = useState({ first : false, second : false })
    const uploadDigital = async (e, n) => {
        if(vouchers[appSettings.currentVoucherKey].crb_amount_4_additional){
            switch (n) {
                case 'first':
                    var formData = new FormData()
                    formData.append("action", "uploadFile")
                    formData.append("userfile", e)

                    let response = await fetch(`${host.url}/wp-json/api/v1/vouchers`, {
                        method: 'POST',
                        body: formData
                    })

                    let result = await response.json();
                    if(result.status === 'success'){
                        setShowInf({...showInf, first : true})
                        setUplF({...uplF, first : true})
                        let st = appSettings.steps
                        let pf = appSettings.preFade
                        st = {...st, st7 : true, st8 : true, st9 : true}
                        pf = {...pf, st7 : false, st8: false}
                        if(uplF.first || uplF.second) {
                            if(parseInt(minOrderD) <= appSettings.toPay) { 
                                setAppSettings({...appSettings, digitalFile : result.fileUrl, steps : st, preFade : pf})  
                            } else {
                                setAppSettings({...appSettings, digitalFile : result.fileUrl})  
                            }
                            
                        }
                        setAppSettings({...appSettings, digitalFile : result.fileUrl})    
                    } else {
                        console.log('There is an error...')
                    }
                    break;
                case 'second':
                    var formData = new FormData()
                    formData.append("action", "uploadFile")
                    formData.append("userfile", e)

                    let response2 = await fetch(`${host.url}/wp-json/api/v1/vouchers`, {
                        method: 'POST',
                        body: formData
                    })

                    let result2 = await response2.json();
                    if(result2.status === 'success'){
                        setShowInf({...showInf, second : true})
                        setUplF({...uplF, second : true})
                        let st = appSettings.steps
                        let pf = appSettings.preFade
                       
                        
                        if(uplF.first || uplF.second) {
                            if(parseInt(minOrderD) <= appSettings.toPay) {
                                st = {...st, st7 : true, st8 : true, st9 : true}
                                pf = {...pf, st7 : false, st8: false}
                                setAppSettings({...appSettings, digitalFileAdd : result2.fileUrl})  
                            } else {
                                setAppSettings({...appSettings, digitalFileAdd : result2.fileUrl})  
                            }
                            
                        }
                        setAppSettings({...appSettings, steps : st, preFade : pf, digitalFileAdd : result2.fileUrl}) 
                    } else {
                        console.log('There is an error...')
                    }
                    break;
            
            }
        } else {
            var formData = new FormData()
            formData.append("action", "uploadFile")
            formData.append("userfile", e)

            let response = await fetch(`${host.url}/wp-json/api/v1/vouchers`, {
                method: 'POST',
                body: formData
            })

            let result = await response.json();
            if(result.status === 'success'){
                setShowInf({...showInf, first : true})
                let st = appSettings.steps
                let pf = appSettings.preFade
                st = {...st, st7 : true, st8 : true, st9 : true}
                pf = {...pf, st7 : false, st8: false}
                if(parseInt(minOrderD) <= appSettings.toPay) {
                    setAppSettings({...appSettings, digitalFile : result.fileUrl, steps : st, preFade : pf})    
                } else {
                    setAppSettings({...appSettings, digitalFile : result.fileUrl}) 
                }
                
            } else {
                console.log('There is an error...')
            }
        }
    }

    useEffect(() => {
        if(window.innerWidth < 1000) {
            if(nomRef.current.length) {
            
                nomRef.current.map((item, id) => {
                    if(item !== null) {
                        const wid = item.querySelector('.input-quantity-wrapper').offsetWidth
                        item.querySelector('.t2').style.left =  (wid - 81) + 'px'
                        item.querySelector('.t2').style.right = 'auto'
                        console.log(wid)
                    }
                    
                })
            }
        }
       
    })

    const updateT2 = () => {
        if(window.innerWidth < 1000) {
            if(nomRef.current.length) {
                nomRef.current.map((item, id) => {
                    if(item !== null) {
                        const wid = item.querySelector('.input-quantity-wrapper').offsetWidth
                        item.querySelector('.t2').style.left =  (wid - 81) + 'px'
                        item.querySelector('.t2').style.right = 'auto'
                    }
                })
            }   
        } else {
            if(nomRef.current.length) {
                nomRef.current.map((item, id) => {
                    if(item !== null) {
                        item.querySelector('.input-result').style.display = 'block'
                        item.querySelector('.t3').style.display = 'none'
                    }
                })
            }   
        }
        
    }



    window.addEventListener('resize', updateT2)

    const digitalMonetaryValueHandler = ( e ) => {

        // console.log(e.target.value.replace(/^0+/, ''))

        // let value = e.target.value.replace('₪', '')
        let value = e.target.value.replace(/^0+/, '')

        if( e.target.value === '' ) {

            value = 0

        }

        const discount = parseInt( value ) - ( parseInt( value ) / 100 * appSettings.discount)

        const currentVoucherKey = appSettings.currentVoucherKey
        
        if(vouchers[currentVoucherKey].crb_amount_4_additional_req_file === true || vouchers[currentVoucherKey].crb_amount_4_req_file === true ){

            // THE PROBLEM
       
            
            setAppSettings({...appSettings, toPay : value, toPayDiscount : discount.toFixed(2).replace(/\.0+$/,'')})

        } else {

            let st = appSettings.steps
            let pf = appSettings.preFade

            if( value !== 0 ){

                st = {...st, st7 : true, st8 : true, st9 : true}
                pf = {...pf, st7 : false, st8: false}        

            } else {

                st = {...st, st7 : true, st8 : false, st9 : false}
                pf = {...pf, st7 : true, st8: true}

            }

            setAppSettings({...appSettings, toPay : value, toPayDiscount : discount.toFixed(2).replace(/\.0+$/,''), steps : st, preFade : pf})

        }       

    }

    useEffect(() => {
        const currentVoucherKey = appSettings.currentVoucherKey
        let st = appSettings.steps
            let pf = appSettings.preFade

            if(appSettings.toPay < parseInt(minOrderD) ){
                st = {...st, st7 : true, st8 : false, st9 : false}
                pf = {...pf, st7 : true, st8: true}
                setAppSettings({...appSettings, steps : st, preFade : pf})
                return
            } 

            if(vouchers[currentVoucherKey].crb_amount_4_additional_req_file === true && vouchers[currentVoucherKey].crb_amount_4_req_file === true && uplF.first && uplF.second){
                if(appSettings.toPay >= parseInt(minOrderD) ){
                    st = {...st, st7 : true, st8 : true, st9 : true}
                    pf = {...pf, st7 : false, st8: false}  
                    setAppSettings({...appSettings, steps : st, preFade : pf})
                } else {
                    // setAppSettings({...appSettings, toPay : value, toPayDiscount : discount.toFixed(2).replace(/\.0+$/,'')})
                }
                return
            }

            if(vouchers[currentVoucherKey].crb_amount_4_req_file === true && uplF.first){
                if(appSettings.toPay >= parseInt(minOrderD) ){
                    st = {...st, st7 : true, st8 : true, st9 : true}
                    pf = {...pf, st7 : false, st8: false}  
                    setAppSettings({...appSettings, steps : st, preFade : pf})
                } else {
                    // setAppSettings({...appSettings, toPay : value, toPayDiscount : discount.toFixed(2).replace(/\.0+$/,'')})
                }
                return
            }

            if(vouchers[currentVoucherKey].crb_amount_4_additional_req_file === true && uplF.second){
                if(appSettings.toPay >= parseInt(minOrderD) ){
                    st = {...st, st7 : true, st8 : true, st9 : true}
                    pf = {...pf, st7 : false, st8: false}  
                    setAppSettings({...appSettings,  steps : st, preFade : pf})
                } 
                return
            }

            

    }, [ appSettings.toPay ])


    const inputRef = useRef( null )
    const removeZero = ( e ) => {
        
        if( e.target.value === '0' ){
            console.log(inpRef.current.value)

            inpRef.current.value = ''
        }

    }


    useEffect(() => {

        let ttp = []
        amo.map(item => {
            ttp.push( item.value )
        })

        let sum=0;
        for(let i=0;i<ttp.length;i++){
            sum = sum + parseInt(ttp[i]);
        }

        setAppSettings({...appSettings, toPay : sum, toPayDiscount : sum - (sum / 100 * appSettings.discount) })

    }, [ amo ])

    const changeNominalHandler = ( e, id ) => {

        nomRef.current[id].querySelector('.nominal-changer').style.borderBottom = '1px solid #000'
        nomRef.current[id].classList.remove('error')

        let value = e.target.value.replace(/^0+/, '')

        if( e.target.value === '' || e.target.value === '0' ) {

            value = 0

        }

        if( parseInt( value ) < parseInt( minAndMax.min ) ||  parseInt( value ) > parseInt( minAndMax.max )) {

            nomRef.current[id].querySelector('.nominal-changer').style.borderBottom = '1px solid red'
            nomRef.current[id].classList.add('error')

        } 

        if( value === 0 ){

            nomRef.current[id].querySelector('.nominal-changer').style.borderBottom = '1px solid #000'
            nomRef.current[id].classList.remove('error')

        }

        nominals.map((item, i) => {

            if( id === i ) {
                let tmp = [...nominals]
                tmp[i] = {...item, crb_amount_val : value} 
                setNominals(tmp)
            
            }
        })

        let amoTmp = []
        amo.map((item, i) => {
            
            let val
            if( id === i) {

                val = {...item, quantity : 0, value : 0}
                amoTmp.push(val)

            } else {

                amoTmp.push(item)

            }
        })

        setAmo( amoTmp )

        

    }

    /**
     * Added
     */
    
    

    const additionalValueHandler = ( e ) => {
        if( e.target.value === '' ) {

            setAdditionsValue( parseInt( 0 ) )

        } else {

            setAdditionsValue( parseInt( e.target.value ) )

        }
        
    }

    useEffect(() => {

        let ttp = []
        amo.map(item => {
            ttp.push( item.value )
        })
        let sum=0;
        for(let i=0;i<ttp.length;i++){
            sum = sum + parseInt(ttp[i]);
        }

        sum = sum + additionalValue

        console.log(sum)

        if(sum <= parseInt(maxForPickUp)) { 
            setDeliveryState({...deliveryState, pickup : true})
        }
        
        if(sum <= parseInt(maxForExpress)) { // was <=
            setDeliveryState({...deliveryState, express : true})
        }

        if(sum < parseInt(minForExpress)) { // was <=
            setDeliveryState({...deliveryState, express : false})
            setDeliveryTypes({...deliveryTypes, paid : false })
        }

        if(appSettings.currentVoucherKey === 3) {
            
            if(sum <= parseInt(deliveryFrom)) {
                setDeliveryState({...deliveryState, delivery : false, express : true})
                setDeliveryTypes({...deliveryTypes, free : false })
            }

        } else {
            if(sum < parseInt(deliveryFrom)) { // was <=
                setDeliveryState({...deliveryState, delivery : false})
                setDeliveryTypes({...deliveryTypes, free : false })
                if(sum >= parseInt(maxForExpress)){
                    setDeliveryState({...deliveryState, express : true})
                } 
                if(sum < parseInt(minForExpress)) { // was <=
                    setDeliveryState({...deliveryState, express : false})
                    setDeliveryTypes({...deliveryTypes, paid : false })
                }
            }
        }
        
       
         

        if(sum < parseInt(minOrder)) {
            
            setDeliveryState({...deliveryState, pickup : false, express : false, delivery : false})
            setDeliveryTypes({ pickUp : false, paid : false, free : false })
        }

        if(sum === 0) {
            setDeliveryState({...deliveryState, pickup : false, express : false, delivery : false})
            setDeliveryTypes({ pickUp : false, paid : false, free : false })
        }

        if(sum >= parseInt(minOrder)) {
                
            setDeliveryState({...deliveryState, pickup : true})

        }

        if(sum >= parseInt(minForExpress)) {
            setDeliveryState({...deliveryState, pickup : true, express : true})  
        }

        if(appSettings.currentVoucherKey === 3) {
            
            sum > parseInt(deliveryFrom) && setDeliveryState({...deliveryState, delivery : true})

            sum > parseInt(maxForExpress) &&  setDeliveryState({...deliveryState, express : false, delivery : true})

            sum > parseInt(maxForExpress) &&  setDeliveryTypes({...deliveryTypes, paid : false })
            sum > parseInt(maxForPickUp) &&  setDeliveryTypes({...deliveryTypes, pickUp : false })
        } else {
            
            sum >= parseInt(deliveryFrom) && setDeliveryState({...deliveryState, delivery : true})

            sum > parseInt(maxForExpress) &&  setDeliveryState({...deliveryState, express : false})
            sum > parseInt(maxForPickUp) &&  setDeliveryState({...deliveryState, pickup : false})

            sum > parseInt(maxForExpress) &&  setDeliveryTypes({...deliveryTypes, paid : false })
            sum > parseInt(maxForPickUp) &&  setDeliveryTypes({...deliveryTypes, pickUp : false })
            
        }
        
        // setAppSettings({...appSettings, toPay : sum, toPayDiscount : (sum - (sum / 100 * appSettings.discount)).toFixed(2) })

    }, [ amo ])


    return(
        <section className={classes.cl} id="step4" style={styles}>
            <div className="wrapper">
                <div className="section-title">
                    <div><span>4</span></div>
                    <p>בחר ערך כספי וכמות התווים</p>
                </div>
                <span style={{
                    display : 'block',
                    color : 'red',
                    fontSize : '20px',
                    paddingBottom : '20px'
                }}>{minAndMax.text}</span>
                <div className="section-4-content">
                    {appSettings.cardType === 'plastic' &&
                    <>
                    <div className="section-4-row col-titles">
                        <span className="col-title"> ערך התו <span>{appSettings.cardType === 'plastic' ? 'המגנטי' : 'דיגיטלי'}</span></span>
                        <span className="col-title">כמות התווים</span>
                        <span className="col-title">סה״כ</span>
                    </div>

                    {nominals.map((item, id) => 
                        <div className="section-4-row row-data error" key={id} ref={el => nomRef.current[id] = el} >
                            <input type="text" 
                                className="nominal-changer"
                                value={item.crb_amount_val}  
                                onChange={ ( e ) => changeNominalHandler( e, id ) } />
                            <div className="input-quantity-wrapper">
                                <input type="number" 
                                    value={amo[id] !== undefined && amo[id].quantity} 
                                    
                                    min="0" 
                                    className="input-quantity"
                                    disabled />
                                <div className="count-control c-plus" onClick={() => changeHandler('plus', id, item.crb_amount_val )} >+</div>
                                <div className="count-control c-minus" onClick={() => changeHandler('minus', id, item.crb_amount_val )} >-</div>
                            </div>
                            <input 
                                ref={el => inpRef.current[id] = el} 
                                type="text" name={item.crb_amount_val} 
                                className="input-result" 
                                value={amo[id] !== undefined && amo[id].value + ' ₪'}
                               
                                disabled
                                />
                            
                            <span class="add-text t1">ערך התו המגנטי</span>
                            <span class="add-text t2">כמות התווים</span>
                            <span class="add-text t3">סה״כ</span>
                            
                        </div>
                    )}
                    </>}
                    
                    {/* {appSettings.cardType === 'plastic' &&
                    <div className="section-4-row row-data" style={{ justifyContent : 'flex-start' }} >
                        <input type="text" value={additionalValue}   onChange={ ( e ) => additionalValueHandler( e ) }  />
                        <span style={{ paddingRight : '30px' }}>סכום חופשי לבקשתכם</span>
                    </div>
                    } */}

                    {appSettings.cardType === 'plastic' &&   
                    <div className="section-4-row total">
                        <span>סה״כ ערך נקוב (תווים)</span>
                        <input type="text" className="input-total" disabled value={appSettings.toPay + ' ₪'}/>
                    </div>}

                    {appSettings.cardType === 'digital' &&
                    <div className="section-4-row total">
                        <span>סה״כ ערך נקוב (תווים)</span>
                        <input 
                            ref={ inputRef }
                            type="text" 
                            className="input-total" 
                            onChange={( e ) => digitalMonetaryValueHandler( e )}
                            onClick={( e ) => removeZero( e )}
                            value={appSettings.toPay}/>
                    </div>}

                    <div className="section-4-row total discount">
                        <span><span id="discountValue">{appSettings.discount}</span>% הנחה לחברי הלשכה</span>
                        <input type="text" className="input-total-with-discount" disabled value={ (appSettings.toPay - appSettings.toPayDiscount).toFixed(2).replace(/\.0+$/,'') + ' ₪'}/>
                    </div>

                    {appSettings.cardType === 'plastic' && 
                    <>
                    {vouchers[appSettings.currentVoucherKey].crb_amount_minimal_order_value_show && 
                    <span className={'delivery-notification'} style={{ display : 'block', paddingTop : '30px', fontSize : '20px', color : 'red' }} > 
                    {vouchers[appSettings.currentVoucherKey].crb_amount_minimal_order + ' ' + minOrder + ' ₪'}</span>}
                    <div className="additional-block" style={{ marginTop : '25px' }}>
                        {vouchers[appSettings.currentVoucherKey].crb_amount_pickup_max_value_show &&
                        <div 
                            className={deliveryState.pickup ? 'section-4-row delivery-additional' : 'section-4-row delivery-additional hidden-delivery'} 
                            style={{marginBottom : '20px', lineHeight : '1.5'}}
                            onClick={(e) => deliveryTypeHandler('pickUp', 0, e)}>
                            {/* Pick UP */}
                            <div className="checkbox-c">
                                <input type="checkbox"/>
                                <span className={deliveryTypes.pickUp ? 'checkmark checked' : 'checkmark'}></span>
                                <span>{vouchers[appSettings.currentVoucherKey].crb_amount_line_1_text}</span>
                            </div>
                            <span className="add-price">0 ₪</span>
                        </div>}

                        {vouchers[currentKey].crb_express_delivery_active &&
                        <div 
                            className={deliveryState.express ? 'section-4-row delivery-additional' : 'section-4-row delivery-additional hidden-delivery'} 
                            style={{marginBottom : '20px', lineHeight : '1.5'}}
                            onClick={(e) => 
                            deliveryTypeHandler('paid', vouchers[currentKey].crb_express_delivery_price, e)} > 
                            {/* Express Delivery */}
                            <div className="checkbox-c">
                                <input type="checkbox"/>
                                <span className={deliveryTypes.paid ? 'checkmark checked' : 'checkmark'}></span>
                                {minD.length === 0 ? 
                                <span>{vouchers[currentKey].crb_amount_line_2_text}</span>
                                :
                                <span>{vouchers[appSettings.currentVoucherKey].crb_amount_line_2_text} ₪  {minD}</span>}
                            </div>
                            <span className="add-price">
                            {vouchers[currentKey].crb_express_delivery_price.length ? vouchers[currentKey].crb_express_delivery_price : 0} ₪</span>
                        </div>}
        
                        <div 
                            className={deliveryState.delivery ? 'section-4-row delivery-additional' : 'section-4-row delivery-additional hidden-delivery'} 
                            style={{marginBottom : '20px', lineHeight : '1.5'}}
                            onClick={(e) => 
                            deliveryTypeHandler('free', vouchers[currentKey].crb_usuall_delivery_price, e)} > 
                            {/* Usuall Delivery */}
                            <div className="checkbox-c">
                                <input type="checkbox" />
                                <span className={deliveryTypes.free ? 'checkmark checked' : 'checkmark'}></span>
                                {minD.length === 0 ? 
                                <span>{vouchers[currentKey].crb_amount_line_3_text}</span> 
                                :
                                <span>{vouchers[currentKey].crb_amount_line_3_text} ₪ {minD}</span>}
                            </div>
                            <span className="add-price">
                            {vouchers[currentKey].crb_usuall_delivery_price.length ? vouchers[currentKey].crb_usuall_delivery_price : 0} ₪</span>
                        </div>
                    </div>
                    </>}
                   
                    <div className="section-4-row" style={{ paddingBottom : '60px' }}>
                        <span className="btn" id="totalToPay">סה״כ לתשלום {parseFloat(appSettings.toPayDiscount) + parseInt(appSettings.deliveryPay)} ₪</span>
                    </div>

                    {appSettings.cardType === 'plastic' && 
                    <div className="section-4-row pac">
                        <span>ברצוני לקבל</span>
                        <input type="text" className="without-arrows" min="0" value={envelops} style={{ width : '120px', margin : '0 15px', lineHeight : '1' }} onChange={(e) => envelopHandler(e)} onClick={emptyEnvelop}  />
                        <span>מעטפות ריקות</span>
                    </div>
                    }
    
                    {appSettings.cardType === 'digital' && 
                    <>
                    {vouchers[appSettings.currentVoucherKey].crb_amount_minimal_order_value_d_show && 
                    <span className={'delivery-notification'} style={{ display : 'block', paddingBottom : '30px', fontSize : '20px', color : 'red' }} > 
                    {vouchers[appSettings.currentVoucherKey].crb_amount_minimal_order_d + ' ' + minOrderD + ' ₪'}</span>}

                    {vouchers[appSettings.currentVoucherKey].crb_amount_4_digital_info_text_req ?
                    <div className="section-4-row pac2" style={{ paddingBottom : '30px' }}>
                        <span className="stl">{vouchers[appSettings.currentVoucherKey].crb_amount_4_digital_info_text}</span>
                    </div>
                    :

                    vouchers[appSettings.currentVoucherKey].crb_amount_4_req_file_upload_block_4 &&
                    <div className="section-4-row pac2">
                        <div className="pac2-content">
                            <span className="tl" style={{ paddingTop : '0' }}>יש למלא את קובץ האקסל עפ״י הפורמט המצורף.</span>
                            <span className="stl">הנתונים בקובץ אקסל – באחריות המזמין בלבד.</span>
                            <div className="pac2-content-buttons" 
                                style={{ paddingBottom: vouchers[appSettings.currentVoucherKey].crb_amount_4_additional ? '20px' : '' }}
                            >
                                <div className="download-btn btn-with-info">
                                    <img src={appSettings.server.url + 'assets/img/download.png'} alt=""/>
                                    <a href={vouchers[appSettings.currentVoucherKey].crb_file} target="_blank">הורדת טופס מקבלי שוברים</a>
                                    <span className="info-tooltip">
                                        <img src={appSettings.server.url + 'assets/img/info.png'} className="btn-info" alt="" />
                                        <div className="hidden-info">
                                          
                                        </div>
                                    </span>

                                </div>
                                <div className="upload-btn btn-with-info">
                                    <img src={appSettings.server.url + 'assets/img/upload.png'}/>
                                    <span>העלת טופס מקבלי שוברים</span>
                                    <span className="info-tooltip">
                                        <img src={appSettings.server.url + 'assets/img/info.png'} className="btn-info" alt="" />
                                        <div className="hidden-info">
                                          
                                        </div>
                                    </span>
                                    
                                    <input className="hidden-input" type="file"  onChange={(e) => uploadDigital(e.target.files[0], 'first')} />
                                    {showInf.first && 
                                    <span className="upload-success-info">קובץ עלה בהצלחה</span>}
                                </div>
                            </div>
                        </div>
  
                    </div>}

                    {vouchers[appSettings.currentVoucherKey].crb_amount_4_additional &&
                    <div className="section-4-row pac2">
                        <div className="pac2-content">
                            <span className="stl">יש למלא כתב התחייבות לפי פורמט המצורף</span>
                            <div className="pac2-content-buttons">
                                <div className="download-btn btn-with-info">
                                    <img src={appSettings.server.url + 'assets/img/download.png'} alt=""/>
                                    <a href={vouchers[appSettings.currentVoucherKey].crb_file_additional} target="_blank">הורדת כתב התחייבות</a>
                                    <span className="info-tooltip">
                                        <img src={appSettings.server.url + 'assets/img/info.png'} className="btn-info" alt="" />
                                        <div className="hidden-info">
                                          
                                        </div>
                                    </span>

                                </div>
                                <div className="upload-btn btn-with-info">
                                    <img src={appSettings.server.url + 'assets/img/upload.png'}/>
                                    <span>העלת כתב התחייבות</span>
                                    <span className="info-tooltip">
                                        <img src={appSettings.server.url + 'assets/img/info.png'} className="btn-info" alt="" />
                                        <div className="hidden-info">
                                          
                                        </div>
                                    </span>
                                    
                                    <input className="hidden-input" type="file"  onChange={(e) => uploadDigital(e.target.files[0], 'second')} />
                                    {showInf.second &&
                                    <span className="upload-success-info">קובץ עלה בהצלחה</span>}
                                </div>
                            </div>
                        </div>   
                    </div>}
                    </>
                    }
                </div>
            </div>
        </section>
    )
}