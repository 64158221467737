import React from 'react'

export const FinalModal = ({appSettings}) => {
    return(
        <div className={'pop-up'}>
            <div className={'pop-up-wrapper'}>
                <h3>הזמנתך נקלטה! אישור הזמנה נשלח לכתובת המייל שלך.</h3>
            </div>
        </div>
    )
}