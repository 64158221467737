import React, { useEffect, useState, useRef } from 'react'
import { useValidate } from '../hooks/validate.hook'

import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

export const PersonalData = React.forwardRef(({ vouchers, appSettings, setAppSettings }, ref) => {

    const [ startDate, setStartDate ] = useState(new Date())
    const [ personalDataInputsR, setPersonalDataInputsR ] = useState([])
    const [ personalDataInputsL, setPersonalDataInputsL ] = useState([])

    const [ personalAdditional, setPersonalAdditional ] = useState([])

    const [ toValidate, setToValidate ] = useState([])
    const [ validationErrors, setValidationErrors ] = useState(true)

    const [ validateError, setValidateError ] = useState(true)

    const inputsRef = useRef([])
    const inputsRef2 = useRef([])

    const { vEmail, vPhone, vCompany } = useValidate()

    useEffect(() => {
        inputsRef.current.map(item => {
            if(item !== null) {
                item.value = ''
                item.style.borderBottom = '1px solid #000'
            }
        })
    }, [appSettings.cardType, appSettings.currentVoucherKey])
    
    useEffect(() => {
        let key = appSettings.currentVoucherKey
        if(appSettings.cardType === 'digital') {
            setPersonalDataInputsR(vouchers[key].crb_cf_rigth_inputs_d)
            setPersonalDataInputsL(vouchers[key].crb_cf_left_inputs_d)
        } else {
            setPersonalDataInputsR(vouchers[key].crb_cf_rigth_inputs)
            setPersonalDataInputsL(vouchers[key].crb_cf_left_inputs)
            setPersonalAdditional(vouchers[key].crb_cf_additional_inputs)
        }
    }, [appSettings.cardType, appSettings.currentVoucherKey])

    useEffect(() => {
        let inputs = []
        personalDataInputsR.map(item => {
            inputs.push({[item.crb_cf_right_input_3] : {content : '', required : item.crb_cf_right_input_required_3, type : item.crb_cf_right_input_select_3} })
        })
        personalDataInputsL.map(item => {
            inputs.push({[item.crb_cf_left_input_3] : {content : '', required : item.crb_cf_left_input_required_3, type : item.crb_cf_left_input_select_3} })
        })
        personalAdditional.map(item => {
            inputs.push({[item.crb_cf_additional_input_3] : {content : '', required : item.crb_cf_additional_input_required_3, type : item.crb_cf_additional_input_select_3} })
        })
        
        setToValidate(inputs)
    }, [ personalDataInputsR, personalDataInputsR, personalAdditional ])
     

    const changeHandler = (e, isRequired, type, blockTitle, blockNumber) => {
        let errorQ = []
       
        const tp = e.target.getAttribute('data-type')
                    
        if(tp === 'email') {
            if(!vEmail(e.target.value)) {
                e.target.style.borderBottom = '1px solid red'
                
            } else {
                setTimeout(() => {
                    e.target.style.borderBottom = '1px solid #000'
                }, 100)
               
            }
        } 

        if(tp === 'phone') {
            if(!vPhone(e.target.value)) {
                e.target.style.borderBottom = '1px solid red'
                return
            } else {
                
                e.target.style.borderBottom = '1px solid #000'
            } 
        }

        if(tp === 'company') {
            
            if(!vCompany(e.target.value)) {
                e.target.style.borderBottom = '1px solid red'
                // e.target.style.borderBottom = '1px solid #000'
                return
                
            } else {
                
                setTimeout(() => {
                    e.target.style.borderBottom = '1px solid #000'
                }, 100)
               
                
            } 
        }
        if(tp === 'text') {
            e.target.style.borderBottom = '1px solid #000'
            
        }

        if(tp === 'date') {
            e.target.style.borderBottom = '1px solid #000'
        }           
                    
        inputsRef.current.map((item, id) => {
            
            if(item !== null) {
                if(item.getAttribute('data-required') === 'true') {
                
                    errorQ.push('er')
                   
                    if(item.value.length > 0) {
                        inputsRef.current[id].setAttribute('data-required', 'false')
                        inputsRef.current[id].style.borderBottom = '1px solid #000'
                    }
                
                } else {
                    
                    
                   
                        
                }
                let stage3Email = appSettings.stage3Email
                if(type === 'email') {
                    stage3Email = {...stage3Email, [e.target.name] : e.target.value}
                }
                let stage3 = appSettings.stage3
                stage3 = {...stage3, [e.target.name] : e.target.value }
                
                /////

                let stage3_blocks = appSettings.stage3_blocks
                let toPlaceContent = stage3_blocks[blockNumber]
                
                let content 
                if(toPlaceContent !== undefined) {
                    content = toPlaceContent.content
                    content = {...content, [e.target.name] : e.target.value}
                }
                
                toPlaceContent = {...toPlaceContent, title : blockTitle, content : content}

                stage3_blocks = {...stage3_blocks, [blockNumber] : toPlaceContent}

                setAppSettings({...appSettings, stage3 : stage3, stage3Email : stage3Email, stage3_blocks : stage3_blocks})
               
            }

        })
    
        if(errorQ.length === 0) {
            if(type === 'email') {
                if(!vEmail(e.target.value)) {
                    e.target.style.borderBottom = '1px solid red'
                    return
                } else {
                    e.target.style.borderBottom = '1px solid #000'
                }
            } 

            if(type === 'phone') {
                if(!vPhone(e.target.value)) {
                    e.target.style.borderBottom = '1px solid red'
                    return
                } else {
                    e.target.style.borderBottom = '1px solid #000'
                } 
            }

            if(type === 'company') {
                
                if(!vCompany(e.target.value)) {
                    e.target.style.borderBottom = '1px solid red'
                    return
                } else {
                    e.target.style.borderBottom = '1px solid #000'
                } 
            }

            if(type === 'text') {
                e.target.style.borderBottom = '1px solid #000'
            }

            if(type === 'date') {
                e.target.style.borderBottom = '1px solid #000'
            }

            let stage3 = appSettings.stage3
            stage3 = {...stage3, [e.target.name] : e.target.value }
                
            let st = appSettings.steps
            let pf = appSettings.preFade
            if(appSettings.cardType === 'plastic') {
                st = {...st, st6 : true}
                pf = {...pf, st4 : false}
            } else {
                st = {...st, st4 : true, st7 : true}
                pf = {...pf, st4 : false}
            }
            let stage3Email = appSettings.stage3Email
            if(type === 'email') {
                stage3Email = {...stage3Email, [e.target.name] : e.target.value}
            }

            let stage3_blocks = appSettings.stage3_blocks
            let toPlaceContent = stage3_blocks[blockNumber]
            
            let content 
            if(toPlaceContent !== undefined) {
                content = toPlaceContent.content
                content = {...content, [e.target.name] : e.target.value}
            }
            
            toPlaceContent = {...toPlaceContent, title : blockTitle, content : content}

            stage3_blocks = {...stage3_blocks, [blockNumber] : toPlaceContent}

            setAppSettings({...appSettings, stage3 : stage3, steps : st, preFade : pf, stage3Email : stage3Email, stage3_blocks : stage3_blocks})

        } else { return }

    }
 
    const styles = {
        display : appSettings.steps.st3 === false && 'none'
    }

    const classes = {
        cl : appSettings.preFade.st3 ? 'section-3 pre-fade' : 'section-3'
    }

    return(
        <section className={classes.cl} id="step3" style={styles} ref={ref}>
            <div className="wrapper">
                <div className="section-title">
                    <div><span>3</span></div>
                    <p>פרטי המזמין ואיש הקשר</p>
                </div>
                
                <div className={appSettings.cardType === 'plastic' ? 'section-3-content personal-data' : 'section-3-content'}>
                   {/* type={item.crb_cf_right_input_select_3}  */}
                    <div className="section-3-content-right personal-data-inputs">
                        <span>פרטי חבר לשכת רו״ח:</span>
                        {personalDataInputsR.map((item, id) => 
                            <div className="input-main" key={id}>
                                {item.crb_cf_right_input_select_3 === 'date' ? 
                                     <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)} 
                                    className="content-input"
                                    name={'date+' + 1}
                                    />
                                : 
                                <input 
                                    ref={el => inputsRef.current[id] = el} 
                                    name={item.crb_cf_right_input_3  + '+' + id + '1'  } 
                                    className="content-input s3-input" 
                                    type={item.crb_cf_right_input_select_3 === 'date' ? 'date' : 'text'}
                                    maxlength={ 
                                            item.crb_cf_right_input_select_3 === 'phone' ? 10 : 
                                            item.crb_cf_right_input_select_3 === 'company' && 5 }
                                    data-required={ item.crb_cf_right_input_required_3  }
                                    // data-required={ 'true' }
                                    data-type={item.crb_cf_right_input_select_3}
                                    required 
                                    onChange={(e) => changeHandler(e, item.crb_cf_right_input_required_3, item.crb_cf_right_input_select_3, 'פרטי חבר לשכת רו״ח:'   , 0 )} 
                                    />}
                                {item.crb_cf_right_input_select_3 !== 'date' &&
                                <span className="input-placeholder" onClick={() => inputsRef.current[id].focus()}>{item.crb_cf_right_input_3}</span>}
                            </div>
                        )}
                        
                    </div>
                    <div className="section-3-content-left personal-data-inputs">
                        {appSettings.cardType === 'plastic' ?
                        <span>פרטי איש קשר ומורשה לקבלת משלוח 1:</span>
                        :       
                        <span>פרטי איש קשר:</span>}
                        {personalDataInputsL.map((item, id) => 
                            <div className="input-main"  key={id}>
                                {item.crb_cf_left_input_select_3 === 'date' ? 
                                     <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)} 
                                    className="content-input"
                                    name={'date+' + 2}
                                    />
                                : 
                                <input 
                                    ref={el => inputsRef.current[id+10] = el} 
                                    name={item.crb_cf_left_input_3 + '+' + id+10 + '2' } 
                                    className="content-input s3-input" 
                                    type="text" 
                                    data-required={ item.crb_cf_left_input_required_3 }
                                    // data-required={ 'true'  }
                                    data-type={ item.crb_cf_left_input_select_3 }
                                    required 
                                    maxlength={ 
                                            item.crb_cf_left_input_select_3 === 'phone' ? 10 : 
                                            item.crb_cf_left_input_select_3 === 'company' && 5 }
                                    onChange={(e) => changeHandler(e, item.crb_cf_left_input_required_3, item.crb_cf_left_input_select_3, appSettings.cardType === 'plastic' ?
                                    'פרטי איש קשר ומורשה לקבלת משלוח 1:'
                                    :       
                                    'פרטי מפיץ התווים:', 1)} />}

                                {item.crb_cf_left_input_select_3 !== 'date' &&
                                <span className="input-placeholder" onClick={() => inputsRef.current[id+10].focus()}>{item.crb_cf_left_input_3}</span>}
                            </div>
                        )}
                         
                    </div>
                    {appSettings.cardType === 'plastic' &&                        
                    <div className="section-3-content-left personal-data-inputs">
                        <span>פרטי איש קשר ומורשה לקבלת משלוח 2:</span>
                        {personalAdditional.map((item, id) => 
                            <div className="input-main"  key={id}>
                                {item.crb_cf_additional_input_select_3 === 'date' ? 
                                     <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)} 
                                    className="content-input"
                                    name={'date+' + 3}
                                    />
                                : 
                                <input 
                                    ref={el => inputsRef.current[id+30] = el} 
                                    name={item.crb_cf_additional_input_3 + '+' + id+20 + '3' } 
                                    className="content-input s3-input" 
                                    type="text" 
                                    data-required={ item.crb_cf_additional_input_required_3  }
                                    // data-required={ 'true'  }
                                    data-type={ item.crb_cf_additional_input_select_3 }
                                    required 
                                    maxlength={ 
                                            item.crb_cf_additional_input_select_3 === 'phone' ? 10 : 
                                            item.crb_cf_additional_input_select_3 === 'company' && 5 }
                                    onChange={(e) => changeHandler(e, item.crb_cf_additional_input_required_3, item.crb_cf_additional_input_select_3, 'פרטי איש קשר ומורשה לקבלת משלוח 2:', 2)} />}
                                {item.crb_cf_left_input_select_3 !== 'date' &&
                                <span className="input-placeholder" onClick={() => inputsRef.current[id+30].focus()}>{item.crb_cf_additional_input_3}</span>}
                            </div>
                        )}   
                    </div>}

                </div>
            </div>
        </section>
    )
})