import React from 'react'

export const FinalPage = () => {
    return(
        <section class="final" dir="rtl">
            <div class="wrapper">
                <h1>הזמנתך התקבלה!</h1>
                <h2>אישור הזמנה נשלח לכתובת המייל שלך</h2>
            </div>
        </section>
    )
}