import React, { useEffect, useState } from 'react'

export const ReceiveOptions = ({ vouchers, appSettings, setAppSettings }) => {

    const [cities, setCities] = useState([])

    useEffect(() => {
        const key = appSettings.currentVoucherKey
        setCities(vouchers[key].crb_6_cities_6)
    }, [appSettings.currentVoucherKey, vouchers])
   
    const styles = {
        display : appSettings.steps.st6 === false && 'none'
    }

    const classes = {
        cl : appSettings.preFade.st6 ? 'section-6 pre-fade' : 'section-6'
    }

    const [showCities, setShowCities] = useState(false)
    
    const showCitiesHandler = () => {
        showCities ? setShowCities(false) : setShowCities(true)
    }

    const [recieveType, setRecieveType] = useState({ pickup : false, delivery : false })
    
    const recieveTypeHandler = (type) => {
        switch (type) {
            case 'pickup':
                setRecieveType({ pickup : false, delivery : false })
                setShowCities(true)
                setAppSettings({...appSettings, delType : 'pickup'})
                break
            case 'delivery':
                setRecieveType({ pickup : false, delivery : true })
                setShowCities(false)
                setChoosenCity({ id : '', title : '' })
                setAppSettings({...appSettings, delType : 'delivery'})
                break    
        }

        let st = appSettings.steps
        let pf = appSettings.preFade
        st = {...st, st8 : true, st9 : true}
        pf = {...pf, st7 : false, st8 : false}
        setAppSettings({...appSettings, steps : st, preFade : pf})
    }

    const [choosenCity, setChoosenCity] = useState({ id : '', title : '' })
    const citySelectHandler = (id, title) => {
        setChoosenCity({ id : id, title : title })
        setShowCities(false)
        setRecieveType({ pickup : true, delivery : false })
        setAppSettings({...appSettings, delType : 'pickup', pickCity : title})
    }

    return(
        <section className={classes.cl} id="step6" style={styles}>
            <div className="wrapper">
                <div className="section-title">
                    <div><span>5</span></div>
                    <p>בחר אפשרות לקבלת התווים</p>
                </div>
                <div className="section-6-content">
                    <div className="checkbox-c with-hidden" onClick={() => recieveTypeHandler('pickup')}>
                        <input type="checkbox" />
                        <span className={recieveType.pickup ? 'checkmark checked' : 'checkmark'}></span>
                        <span>איסוף עצמי</span>
                        <img className="show-hidden" src={appSettings.server.url + 'assets/img/chevron-down.png'} alt=""/>
                        <span className="choosen-city">{choosenCity.title}</span>
                    </div>
                    
                    {showCities &&
                    <div className="hidden-list" style={{ display : 'block' }}>
                        <div className="hidden-list__title">בחר סניף ממנו תאסוף/י את תווי הקניה</div>
                        {/* <div className="hidden-list__search">
                            <img src={appSettings.server.url + 'assets/img/search.png'} alt=""/>
                            <input type="text" placeholder="האומן 15, תלפיות ירושלים" className="search-input" id="searchCity"/>
                        </div> */}
                        <div className="hidden-list__content">
                            {cities.map((item, id) =>
                                <div className="checkbox-c city-select" onClick={() => citySelectHandler(id, item.crb_6_city)}>
                                    <input type="checkbox" />
                                    <span className={choosenCity.id === id ? 'checkmark checked' : 'checkmark'}></span>
                                    <span className="ch-city">{item.crb_6_city}</span>
                                </div>
                            )}  
                        </div>
                    </div>}

                </div>
            </div>
        </section>
    )
}