import React from 'react'

import parse from 'html-react-parser';

export const RemarksBlock = React.forwardRef(({vouchers, appSettings}, ref) => {
    
    const styles = {
        display : appSettings.steps.st7 === false && 'none'
    }

    const classes = {
        cl : appSettings.preFade.st7 ? 'section-7 pre-fade' : 'section-7'
    }

    return(
        <section className={classes.cl} id="step7" style={styles} ref={ref}> 
            <div className="wrapper">
                <div className="section-title">
                    <div><span>

                    {/* {appSettings.cardType === 'plastic' ? 
                    appSettings.deliveryType === 'paid' || appSettings.deliveryType === 'free' ? '6' :
                    appSettings.deliveryType === 'pickUp' ? '6' : '6' : '5' } */}
                      {appSettings.cardType === 'plastic' ? 
                    appSettings.deliveryType === 'paid' || appSettings.deliveryType === 'free' ? '5' :
                    appSettings.deliveryType === 'pickUp' ? '6' : '5' : '5' }
                    </span></div>
                    
                    <p>הערות</p>
                </div>
                <div className="section-7-content">
                    
                    {appSettings.cardType === 'plastic' ? parse(vouchers[appSettings.currentVoucherKey].crb_7_content_7) :
                    parse(vouchers[appSettings.currentVoucherKey].crb_7_content_7_d)}
                </div>
            </div>
        </section>)
})