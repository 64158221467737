import React, { useState } from 'react'

export const useValidate = () => {

    const [error, setError] = useState(null)

    const vEmail = (value) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(value);
    }

    const vPhone = (value) => {
        // const re = /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/
        let re
        if(value.length === 9) {
            re = /^\d{9}$/
        } else if(value.length === 10) {
            re = /^\d{10}$/
        } else {
            re = /^\d{10}$/
        }
        
        return re.test(value)
    }

    const vCompany = (value) => {
        const re = /^\d{5}$/
        // const re =  /^[0-9]+$/
        return re.test(value)
    }

    return { vEmail, vPhone, vCompany, error }
}