import React from 'react'
import { MobileSlider } from './MobileSlider'


export const Cards = React.forwardRef(({ vouchers, appSettings, setAppSettings, scrollTo }, ref) => {
    
    const cartTypeHandler = (id, url, discount, e, title) => {

        if(e.target.classList[0] !== 'info-button') {
            let st = appSettings.steps
            let pf = appSettings.preFade


            st = {...st, st3 : true, st4 : false, st5 : false, st6 : false, st7 : false, st8 : false, st9 : false, st10 : false}
            pf = {...pf, st2 : false, st3 : true, st4 : true, st5 : true, st6 : true, st7 : true, st8 : true, st9 : true, st10 : true}

            setAppSettings({
                ...appSettings, 
                initialState : false, 
                currentVoucherKey : id, 
                discount : parseFloat( discount ), 
                cardImgUrl : url, 
                cardTitle : title,
                steps : st, 
                preFade : pf,
                deliveryPay : 0,
                toPayDiscount : 0,
                toPay : 0,
                cardType : '',
                paymentType : ''
                })
            scrollTo(1)
        }
           
    }

    const showInfoHandler = (id) => {
        setAppSettings({...appSettings, infoModal : true, infoContent : id})
    }

    return(
        <section className="section-1 cards" id="step1" ref={ref}>
            <div className="wrapper">
                <div className="section-title">
                    <div><span>1</span></div>
                    <p>בחר סוג כרטיס</p>
                </div>
                <div className="cards-content">
                    {vouchers.map((item, id) => 
                        <div 
                            className={appSettings.initialState ? 'cards__item' : appSettings.currentVoucherKey === id ? 'cards__item selected-card' : 'cards__item'} 
                            key={id} 
                            onClick={(e) => cartTypeHandler(id, item.crb_card_img1, item.crb_card_discount1, e, item.title)} >
                           
                            <div className="card__item-img" 
                                style={{backgroundImage : `url(${item.crb_card_img1})`, backgroundRepeat : 'no-repaet', backgroundSize : 'cover'}} ></div>
                            <div className="card__item-info">
                                <span className="card__item-info__title">{item.title}</span>
                                <span className="card__item-info__discount">{item.crb_card_discount1}%</span>
                                <span className="card__item-info__i">
                                    הנחה בעת הרכישת התווים
                                    <a href={item.crb_pop_up_file_pdf} target="_blank"><img className="info-button" src={appSettings.server.url + 'assets/img/info.png'} alt=""/></a>
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                <MobileSlider vouchers={vouchers} appSettings={appSettings} cartTypeHandler={cartTypeHandler} />
            </div>
        </section>
    )
})