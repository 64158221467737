import React, { useState, useEffect, useRef } from 'react'
import CanvasDraw from "react-canvas-draw"
import { useIsMobileOrTablet } from "../utils/isMobileOrTablet"
import { useValidate } from '../hooks/validate.hook'

import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

export const FinalBlock = ({ vouchers, appSettings, setAppSettings }) => {

    const [startDate, setStartDate] = useState()

    const { vEmail, vPhone, vCompany } = useValidate()
    const inputsRef = useRef([])

    const [inputsR, setInputsR] = useState([])
    const [inputsL, setInputsL] = useState([])

    const [canvas, setCanvas] = useState(false)

    const isMobOrTab = useIsMobileOrTablet()

    useEffect(() => {
        inputsRef.current.map(item => {
            if(item !== null) {
                item.value = ''
                item.style.borderBottom = '1px solid #000'
            }
        })
        let signRequired 
        if(appSettings.cardType === 'digital') {
            setAppSettings({...appSettings, signIsRequired : vouchers[appSettings.currentVoucherKey].crb_9_sign_d})
        } else {
            setAppSettings({...appSettings, signIsRequired : vouchers[appSettings.currentVoucherKey].crb_9_sign})
        }
    }, [appSettings.cardType, appSettings.currentVoucherKey])
    
    useEffect(() => {
        let key = appSettings.currentVoucherKey
        if(appSettings.cardType === 'digital') {
            setInputsR(vouchers[key].crb_9_rigth_inputs_9_d)
            setInputsL(vouchers[key].crb_9_left_inputs_9_d)
        } else {
            setInputsR(vouchers[key].crb_9_rigth_inputs_9)
            setInputsL(vouchers[key].crb_9_left_inputs_9)
        }
    }, [appSettings.cardType, appSettings.currentVoucherKey])

    const changeHandler = (e, isRequired, type,blockTitle, blockNumber  ) => {
       
        let errorQ = []
        inputsRef.current.map((item, id) => {
            if(item !== null) {
                if(item.getAttribute('data-required') === 'true') {
                    errorQ.push('er')
                    if(item.value.length > 0) {
                        inputsRef.current[id].setAttribute('data-required', 'false')
                        inputsRef.current[id].style.borderBottom = '1px solid #000'
                    }
                    
                } else {
                    const tp = item.getAttribute('data-type')
                    if(tp === 'email') {
                        if(!vEmail(e.target.value)) {
                            e.target.style.borderBottom = '1px solid red'
                            return
                        } else {
                            e.target.style.borderBottom = '1px solid #000'
                        }
                    } 
    
                    if(tp === 'phone') {
                        if(!vPhone(e.target.value)) {
                            e.target.style.borderBottom = '1px solid red'
                            return
                        } else {
                            e.target.style.borderBottom = '1px solid #000'
                        } 
                    }
    
                    if(tp === 'company') {
                        if(!vCompany(e.target.value)) {
                            e.target.style.borderBottom = '1px solid red'
                            return
                        } else {
                            e.target.style.borderBottom = '1px solid #000'
                        } 
                    }
    
                    if(tp === 'text') {
                        e.target.style.borderBottom = '1px solid #000'
                    }
                        
                }
                
                let stage9 = appSettings.stage9
                stage9 = {...stage9, [e.target.name] : e.target.value }
                let stage9Email = appSettings.stage9Email
                if(type === 'email') {
                    stage9Email = {...stage9Email, [e.target.name] : e.target.value}
                }

                let stage9_blocks = appSettings.stage9_blocks
                let toPlaceContent = stage9_blocks[blockNumber]
                
                let content 
                if(toPlaceContent !== undefined) {
                    content = toPlaceContent.content
                    content = {...content, [e.target.name] : e.target.value}
                }
                
                toPlaceContent = {...toPlaceContent, title : blockTitle, content : content}


                stage9_blocks = {...stage9_blocks, [blockNumber] : toPlaceContent}

                setAppSettings({...appSettings, stage9 : stage9, stage9Email : stage9Email, stage9_blocks : stage9_blocks})
            }
            
            
        })

        if(errorQ.length === 0) {
            if(type === 'email') {
                if(!vEmail(e.target.value)) {
                    e.target.style.borderBottom = '1px solid red'
                    return
                } else {
                    e.target.style.borderBottom = '1px solid #000'
                }
            } 

            if(type === 'phone') {
                if(!vPhone(e.target.value)) {
                    e.target.style.borderBottom = '1px solid red'
                    return
                } else {
                    e.target.style.borderBottom = '1px solid #000'
                } 
            }

            if(type === 'company') {
                if(!vCompany(e.target.value)) {
                    e.target.style.borderBottom = '1px solid red'
                    return
                } else {
                    e.target.style.borderBottom = '1px solid #000'
                } 
            }

            if(type === 'text') {
                e.target.style.borderBottom = '1px solid #000'
            }

            if(type === 'date') {
                e.target.style.borderBottom = '1px solid #000'
            }

            let stage9 = appSettings.stage9
            stage9 = {...stage9, [e.target.name] : e.target.value }
            let st = appSettings.steps
            let pf = appSettings.preFade
            pf = {...pf, st10 : false}
            let stage9Email = appSettings.stage9Email
            if(type === 'email') {
                stage9Email = {...stage9Email, [e.target.name] : e.target.value}
            }

            let stage9_blocks = appSettings.stage9_blocks
                let toPlaceContent = stage9_blocks[blockNumber]
                
                let content 
                if(toPlaceContent !== undefined) {
                    content = toPlaceContent.content
                    content = {...content, [e.target.name] : e.target.value}
                }
                
                toPlaceContent = {...toPlaceContent, title : blockTitle, content : content}
                

                stage9_blocks = {...stage9_blocks, [blockNumber] : toPlaceContent}

            setAppSettings({...appSettings, stage9 : stage9, preFade : pf, stage9Email : stage9Email, stage9_blocks : stage9_blocks})

            } else { return }

    }

    const styles = {
        display : appSettings.steps.st9 === false && 'none'
    }

    const classes = {
        cl : appSettings.preFade.st9 ? 'section-9 pre-fade' : 'section-9'
    }

    const openCanvas = () => {
        canvas ? setCanvas(false) : setCanvas(true)
    }

    const defaultProps = {
        onChange: null,
        loadTimeOffset: 5,
        lazyRadius: 0,
        brushRadius: 2,
        brushColor: "#444",
        catenaryColor: "#0a0302",
        gridColor: "rgba(150,150,150,0.17)",
        hideGrid: true,
        canvasWidth: window.screen.availWidth < 800 ? "" : 466,
        canvasHeight: 350,
        disabled: false,
        imgSrc: "",
        saveData: null,
        immediateLoading: false,
        hideInterface: false
    }
    
    const canva = useRef()
    
    const saveImg = () => {
        const image = canva.current.canvasContainer.childNodes[1].toDataURL()
        setAppSettings({...appSettings, finalSign : image, showSignNotification : false})
    }

    const clearCanvas = () => {
        canva.current.clear()   
    }

    const dateHandler = (date, name, blockTitle, blockNumber) => {
        if(date !== null) {
            setStartDate(date)
            const day = date.getDate()
            const month = date.getMonth() + 1
            const year = date.getFullYear()  
            let stage9 = appSettings.stage9
            stage9 = {...stage9, [name] : `${day}/${month}/${year}`} 

            let stage9_blocks = appSettings.stage9_blocks
            let toPlaceContent = stage9_blocks[blockNumber]
                
            let content 

            content = toPlaceContent.content
            content = {...content, [name] : `${day}/${month}/${year}`}
                
            toPlaceContent = {...toPlaceContent, title : blockTitle, content : content}

            stage9_blocks = {...stage9_blocks, [blockNumber] : toPlaceContent}

            console.log('Date: ' + date, 'Name: ' + name, 'Title: ' + blockTitle, 'Number: ' + blockNumber)

            setAppSettings({...appSettings, stage9 : stage9, stage9_blocks : stage9_blocks})

        }
    }

    return(
        <section className={classes.cl} id="step9" style={styles}>
            <div className="wrapper">
                <div className="section-title">
                    <div><span>
                    {/* {appSettings.cardType === 'plastic' ? 
                    appSettings.deliveryType === 'paid' || appSettings.deliveryType === 'free' ? '8' :
                    appSettings.deliveryType === 'pickUp' ? '8' : '8' : '7' } */}
                     {appSettings.cardType === 'plastic' ? 
                    appSettings.deliveryType === 'paid' || appSettings.deliveryType === 'free' ? '7' :
                    appSettings.deliveryType === 'pickUp' ? '8' : '7' : '7' }
                    </span></div>
                    <p>אישור</p>
                </div>
                <div className="section-9-content">
                       
                        <div className="section-3-content-right">
                            <span>פרטי חבר לשכת רו״ח:</span>
                            {inputsR.map((item, id) => 
                                <div className="input-main" key={id}>
                                    {item.crb_9_right_input_select === 'date' ? 
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => dateHandler(date, item.crb_9_right_input + '+' + id + '1', 'פרטי חבר לשכת רו״ח:', 0)} 
                                        className="content-input"
                                        dateFormat="dd/MM/yyyy"
                                        name={item.crb_9_right_input + '+' + id + '1'} 
                                        
                                        placeholderText="תאריך" 
                                    />
                                    : 
                                    <input 
                                        ref={el => inputsRef.current[id] = el} 
                                        placeholder="" 
                                        name={item.crb_9_right_input + '+' + id + '1'}  
                                        className="content-input s9-input" 
                                        required 
                                        onChange={(e) => changeHandler(e, item.crb_9_right_input_required, item.crb_9_right_input_select, 'פרטי חבר לשכת רו״ח:', 0)}
                                        maxlength={ 
                                        item.crb_9_right_input_select === 'phone' ? 10 : 
                                        item.crb_9_right_input_select === 'company' && 5 }
                                        // data-required={ item.crb_9_right_input_required  }
                                        data-required={ 'true' }
                                        data-type={item.crb_9_right_input_select}
                                     />}
                                    {item.crb_9_right_input_select !== 'date' &&
                                    <span className="input-placeholder" onClick={() => inputsRef.current[id].focus()}>{item.crb_9_right_input}</span>}
                                </div>
                            )}
                             
                            <a className="section-9-btn sign-open" onClick={openCanvas} >חתימה</a>

                            <div className="sign-area" style={{ display : canvas ? 'block' : 'none' }}>
                                <CanvasDraw 
                                    ref={canva}  
                                    {...defaultProps} 
                                    style={{ backgroundColor: 'rgb(245, 245, 245)', border: '1px solid #ccc',marginTop: '15px'}} 
                                    onChange={saveImg} />
                                <span className="clear-sign" id="clearSign" onClick={clearCanvas}>נקה</span>
                            </div>
                            {appSettings.showSignNotification &&
                            <span className="clear-sign" style={{color : 'red'}}>חתימה שדה חובה</span>}   
                              
                        </div>
                        {inputsL.length > 0 &&
                        <div className="section-3-content-left">
                            <span>קבלה על שם:</span>
                            {inputsL.map((item, id) => 
                                <div className="input-main" key={id}>
                                    {item.crb_9_left_input_select === 'date' ? 
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => dateHandler(date, item.crb_9_left_input + '+' + id+10 + '2', 'קבלה על שם:', 1)} 
                                        className="content-input"
                                        dateFormat="dd/MM/yyyy"
                                        name={item.crb_9_left_input + '+' + id+10 + '2'} 
                                        placeholderText="תאריך" 
                                    />
                                    : 
                                    <input 
                                        ref={el => inputsRef.current[id+10] = el} 
                                        placeholder="" 
                                        name={item.crb_9_left_input + '+' + id+10 + '2'} 
                                        className="content-input s9-input" 
                                        onChange={(e) => changeHandler(e, item.crb_9_left_input_required, item.crb_9_left_input_select, 'קבלה על שם:', 1)}
                                        // data-required={ item.crb_9_left_input_required  }
                                        data-required={ 'true'  }
                                        data-type={ item.crb_9_left_input_select }
                                        required 
                                        maxlength={ 
                                            item.crb_9_left_input_select === 'phone' ? 10 : 
                                            item.crb_9_left_input_select === 'company' && 5 } 
                                    />}
                                    {item.crb_9_left_input_select !== 'date' &&
                                    <span className="input-placeholder" onClick={() => inputsRef.current[id+10].focus()}>{item.crb_9_left_input}</span>}
                                </div>
                            )}                   
                        </div>}
   
                </div>
            </div>
        </section>
    )
}