import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";

export const MobileSlider = ({ vouchers, appSettings, cartTypeHandler }) => {

    const [windowWidth, setWindowWidth] = useState( window.innerWidth )

    const [cPadding, setCPadding] = useState( '65px' )

    useEffect(() => {

        let cPad = '0px'
        if(windowWidth < 800) {
            cPad = '225px'
        }
        if(windowWidth < 755) {
            cPad = '85px'
        }
        if(windowWidth < 450) {
            cPad = '85px'
        }
        if(windowWidth < 390) {
            cPad = '65px'
        }
        if(windowWidth < 350) {
            cPad = '40px'
        }

        setCPadding(cPad)
        
    }, [windowWidth])

    const updateSize = () => {
        setWindowWidth( window.screen.availWidth )
    }

    window.addEventListener('resize', updateSize)

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: cPadding,
        slidesToShow: 1,
        speed: 800
    }

    return(
        <Slider {...settings}>
            {vouchers.map((item, id) => 
                <div 
                className={appSettings.currentVoucherKey === id ? 'cards__item selected-card' : 'cards__item'}
               className={appSettings.initialState ? 'cards__item' : appSettings.currentVoucherKey === id ? 'cards__item selected-card' : 'cards__item'} 
                key={id} 
                onClick={(e) => cartTypeHandler(id, item.crb_card_img1, item.crb_card_discount1, e)}
                >
               
                    <div className="card__item-img" 
                        style={{backgroundImage : `url(${item.crb_card_img1})`, backgroundRepeat : 'no-repaet', backgroundSize : 'cover'}} ></div>
                    <div className="card__item-info" dir="rtl">
                        <span className="card__item-info__title">{item.title}</span>
                        <span className="card__item-info__discount">{item.crb_card_discount1}%</span>
                        <span className="card__item-info__i">
                            הנחה בעת הרכישת התווים
                            <a href={item.crb_pop_up_file_pdf} target="_blank"><img className="info-button" src={appSettings.server.url + 'assets/img/info.png'} alt=""/></a>
                        </span>
                    </div>
                </div>
            )}
        </Slider>
    )
}