import React, { useContext, useState } from 'react'
import { HttpContext } from '../context/HttpContext'
import { useHttp } from '../hooks/http.hook'

export const SubmitButtons = ({ vouchers, appSettings, setAppSettings, initialState, getVouchers }) => {

    const { request } = useHttp()
    const host = useContext(HttpContext)

    const [disableBtn, setDisableBtn] = useState(false)

    const morecardsHandler = async (e) => {
        if(appSettings.signIsRequired) {
            if(appSettings.finalSign === '') {
                setAppSettings({...appSettings, showSignNotification : true})
                return
            }
        }
        e.preventDefault()
        setDisableBtn(true)
        setAppSettings({...appSettings, finalModal : true})
        try{
            const response = request(`${host.url}/wp-json/api/v1/vouchers`, 'POST', { action : 'saveOrder', data : appSettings }, { 'Content-Type' : 'application/json', 'Authorization' : `Bearer ` }) 
            response.then(data => {
                setDisableBtn(false)
                setTimeout(() => {
                    window.scrollTo({top: 0, behavior: 'smooth'})
                    setAppSettings({...appSettings, finalModal : false})
                    setTimeout(() => {
                        window.location.reload()
                    }, 500)
                }, 2000)
            })
        } catch(e) { console.log(e) }
        
    }

    const finalHandler = async (e) => {
        if(appSettings.signIsRequired) {
            if(appSettings.finalSign === '') {
                setAppSettings({...appSettings, showSignNotification : true})
                return
            }
        }
        e.preventDefault()
        setDisableBtn(true)
        try{
            const response = request(`${host.url}/wp-json/api/v1/vouchers`, 'POST', { action : 'saveOrder', data : appSettings }, { 'Content-Type' : 'application/json', 'Authorization' : `Bearer ` }) 
            response.then(data => {
                setTimeout(() => {
                    window.location.href = host.ur + '/thank-you'
                    // window.scrollTo({top: 0, behavior: 'smooth'})
                    // setAppSettings({...appSettings, finalPage : true})
                    // setDisableBtn(false)
                }, 2000)
            })
        } catch(e) { console.log(e) }

    }

    
    const styles = {
        display : appSettings.steps.st10 === false && 'none'
    }

    const classes = {
        cl : appSettings.preFade.st10 ? 'section-10 pre-fade' : 'section-10'
    }

    return(
        <section className={classes.cl} id="step10" style={styles}>
            <div className="wrapper">
                <div className="section-10-buttons">
                    {/* <button href="#" 
                        className={disableBtn ? 'btn btn-lg btn-dark form-final more-cards dis' : 'btn btn-lg btn-dark form-final more-cards'}
                        disabled={disableBtn ? true : false} 
                        onClick={(e) => morecardsHandler(e)}>קנה תווים נוספים</button> */}
                    <button href="#" 
                        className={disableBtn ? 'btn btn-lg btn-light form-final finish-cards dis' : 'btn btn-lg btn-light form-final finish-cards'}
                        disabled={disableBtn ? true : false} 
                        onClick={(e) => finalHandler(e)}>לסיום רכישה</button>
                </div>
                <div>
                    <p>הטיפול בהזמנה יחל עם הגעת טופס מלא ויבוצע תוך 10 ימי עסקים</p>
                </div>
            </div>
        </section>
    )
}