import React, { useState, useContext } from 'react'
import { HttpContext } from '../context/HttpContext'
import { useHttp } from '../hooks/http.hook'

export const PaymentType = ({ vouchers, appSettings, setAppSettings }) => {

    const { request } = useHttp()
    const host = useContext(HttpContext)
    const styles = {
        display : appSettings.steps.st8 === false && 'none'
    }

    const classes = {
        cl : appSettings.preFade.st8 ? 'section-8 pre-fade' : 'section-8'
    }

    const paymentTypeHandler = (type) => {
        let st = appSettings.steps
        let pf = appSettings.preFade
        if(type === 'send') {
            st = {...st, st10 : true}
            pf = {...pf, st9 : false}
            setAppSettings({...appSettings, steps : st, preFade : pf, paymentType : type, paymentFile : ''})
            setFileName('')
            setFileVal('')
        } else {
            st = {...st, st10 : false}
            pf = {...pf, st9 : true}
            setAppSettings({...appSettings, steps : st, preFade : pf, paymentType : type})
        }
       
    }
    
    const [fileName, setFileName] = useState()
    const [fileVal, setFileVal] = useState()
    const uploadPayment = async (e) => {
        
        setFileName(e.name)
        var formData = new FormData()
        formData.append("action", "uploadFile")
        formData.append("userfile", e)

        let response = await fetch(`${host.url}/wp-json/api/v1/vouchers`, {
            method: 'POST',
            body: formData
        })

        let result = await response.json();
        if(result.status === 'success'){
            let st = appSettings.steps
            let pf = appSettings.preFade
            st = {...st, st10 : true}
            pf = {...pf, st9 : false}
            setAppSettings({...appSettings, paymentFile : result.fileUrl, steps : st, preFade : pf, paymentType : 'bankTransfer'})
        } else {
            console.log('There is an error...')
        }
        
    }

    return(
        <section className={classes.cl} id="step8" style={styles}>
            <div className="wrapper">
                <div className="section-title">
                    <div><span>
                    {/* {appSettings.cardType === 'plastic' ? '8' : '6'} */}
                    {/* {appSettings.cardType === 'plastic' ? 
                    appSettings.deliveryType === 'paid' || appSettings.deliveryType === 'free' ? '7' : '8' : '6'} */}

                    {/* {appSettings.cardType === 'plastic' ? 
                    appSettings.deliveryType === 'paid' || appSettings.deliveryType === 'free' ? '7' :
                    appSettings.deliveryType === 'pickUp' ? '7' : '7' : '6' } */}
                    {appSettings.cardType === 'plastic' ? 
                    appSettings.deliveryType === 'paid' || appSettings.deliveryType === 'free' ? '6' :
                    appSettings.deliveryType === 'pickUp' ? '7' : '6' : '6' }
                    </span></div>
                    <p>בחר סוג תשלום</p>
                </div>
                <div className="section-8-content">
                    {/* <div className="checkbox-c" onClick={() => paymentTypeHandler('send')}>
                        <input type="checkbox" />
                        <span className={appSettings.paymentType === 'send' ? 'checkmark checked' : 'checkmark'}></span>
                        <span>שיק</span>
                    </div>

                    <ul>
                        <li>השקים במזומן בלבד - לא יתקבלו שקים דחויים.</li>
                        <li>הטיפול בהזמנה יחל עם הגעת השיק ללשכת רואי חשבון.</li>
                    </ul> */}

                    <div className="checkbox-c" onClick={() => paymentTypeHandler('bankTransfer')}>
                        <input type="checkbox" />
                        <span className={appSettings.paymentType === 'bankTransfer' ? 'checkmark checked' : 'checkmark'}></span>
                        <span>בהעברה בנקאית</span>
                    </div>

                    <span className="bank-req">לחשבון מס' 44770078, סניף 811, בנק לאומי מס' 10</span>

                    <div className="upload-btn">
                        <img src={appSettings.server.url + 'assets/img/upload.png'} alt="" />
                        <span>יש לצרף טופס אישור ההעברה מהבנק - ללא צרוף הטופס לא נוכל לטפל בבקשה.</span>
                        <input className="hidden-input" type="file" value={fileVal} onChange={(e) => uploadPayment(e.target.files[0])}  />
                        <span className="uploaded-file">{fileName}</span>
                    </div>
                    
                </div>
            </div>
        </section>
    )
}