import React, { useState, useEffect, useRef } from 'react'
import { useValidate } from '../hooks/validate.hook'

import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"


export const DeliveryDetails = ({ vouchers, appSettings, setAppSettings }) => {

    const [startDate, setStartDate] = useState(new Date())

    const [inputsR, setInputsR] = useState([])
    const [inputsL, setInputsL] = useState([])
    const { vEmail, vPhone, vCompany } = useValidate()
    const inputsRef = useRef([])

    useEffect(() => {
        inputsRef.current.map(item => {
            if(item !== null) {
                item.value = ''
                item.style.borderBottom = '1px solid #000'
            }
        })
    }, [appSettings.cardType, appSettings.currentVoucherKey])
    
    useEffect(() => {
        let key = appSettings.currentVoucherKey
        if(appSettings.cardType === 'digital') {
            setInputsR([])
            setInputsL([])
        } else {
            setInputsR(vouchers[key].crb_5_rigth_inputs_5)
            setInputsL(vouchers[key].crb_5_left_inputs_5)
        }
    }, [appSettings.cardType, appSettings.currentVoucherKey])

    const changeHandler = (e, isRequired, type, blockTitle, blockNumber) => {

        let errorQ = []
        inputsRef.current.map((item, id) => {
            
            if(item.getAttribute('data-required') === 'true') {
                errorQ.push('er')
                if(item.value.length > 0) {
                    inputsRef.current[id].setAttribute('data-required', 'false')
                    inputsRef.current[id].style.borderBottom = '1px solid #000'
                }
                
            } else {
                const tp = item.getAttribute('data-type')
                if(tp === 'email') {
                    if(!vEmail(e.target.value)) {
                        e.target.style.borderBottom = '1px solid red'
                        return
                    } else {
                        e.target.style.borderBottom = '1px solid #000'
                    }
                } 

                if(tp === 'phone') {
                    if(!vPhone(e.target.value)) {
                        e.target.style.borderBottom = '1px solid red'
                        return
                    } else {
                        e.target.style.borderBottom = '1px solid #000'
                    } 
                }

                if(tp === 'company') {
                    if(!vCompany(e.target.value)) {
                        e.target.style.borderBottom = '1px solid red'
                        return
                    } else {
                        e.target.style.borderBottom = '1px solid #000'
                    } 
                }

                if(tp === 'text') {
                    e.target.style.borderBottom = '1px solid #000'
                }
                    
            }

            let stage5Email = appSettings.stage5Email
            if(type === 'email') {
                stage5Email = {...stage5Email, [e.target.name] : e.target.value}
            }
            let stage5 = appSettings.stage5
            stage5 = {...stage5, [e.target.name] : e.target.value }
                
            /////

            let stage5_blocks = appSettings.stage5_blocks
            let toPlaceContent = stage5_blocks[blockNumber]
                
            let content 
            if(toPlaceContent !== undefined) {
                content = toPlaceContent.content
                content = {...content, [e.target.name] : e.target.value}
            }
                
            toPlaceContent = {...toPlaceContent, title : blockTitle, content : content}

            stage5_blocks = {...stage5_blocks, [blockNumber] : toPlaceContent}

            setAppSettings({...appSettings, stage5 : stage5, stage5Email : stage5Email, stage5_blocks : stage5_blocks})
            
        })

        if(errorQ.length === 0) {
            if(type === 'email') {
                if(!vEmail(e.target.value)) {
                    e.target.style.borderBottom = '1px solid red'
                    return
                } else {
                    e.target.style.borderBottom = '1px solid #000'
                }
            } 

            if(type === 'phone') {
                if(!vPhone(e.target.value)) {
                    e.target.style.borderBottom = '1px solid red'
                    return
                } else {
                    e.target.style.borderBottom = '1px solid #000'
                } 
            }

            if(type === 'company') {
                if(!vCompany(e.target.value)) {
                    e.target.style.borderBottom = '1px solid red'
                    return
                } else {
                    e.target.style.borderBottom = '1px solid #000'
                } 
            }

            if(type === 'text') {
                e.target.style.borderBottom = '1px solid #000'
            }

            if(type === 'date') {
                e.target.style.borderBottom = '1px solid #000'
            }

            let stage5 = appSettings.stage5
            stage5 = {...stage5, [e.target.name] : e.target.value }
            let st = appSettings.steps
            let pf = appSettings.preFade
            
            if(appSettings.deliveryType === 'pickUp') {
                st = {...st, st6 : false, st7 : true, st8 : true, st9 : true}
                pf = {...pf, st7 : false, st8 : false}
            } else {
                st = {...st, st7 : true, st8: true, st9: true}
                pf = {...pf, st7 : false, st8 : false}
            }

            let stage5_blocks = appSettings.stage5_blocks
            let toPlaceContent = stage5_blocks[blockNumber]
                
            let content 
            if(toPlaceContent !== undefined) {
                content = toPlaceContent.content
                content = {...content, [e.target.name] : e.target.value}
            }
                
            toPlaceContent = {...toPlaceContent, title : blockTitle, content : content}

            stage5_blocks = {...stage5_blocks, [blockNumber] : toPlaceContent}
            
            setAppSettings({...appSettings, stage5 : stage5, steps : st, preFade : pf, stage5_blocks : stage5_blocks})

        } else { return }

    }

    const styles = {
        display : appSettings.steps.st5 === false && 'none',
        // opacity : 0
    }

    const classes = {
        cl : appSettings.preFade.st5 ? 'section-5 pre-fade' : 'section-5'
    }
    

    return(
        <section className={classes.cl} id="step5" style={styles}>
            <div className="wrapper">
                <div className="section-title">
                    <div><span>5</span></div>
                    <p>פרטי מורשים לקבלת משלוח</p>
                </div>
                <div className="section-5-content">
                    <div className="section-3-content">
                        
                        <div className="section-3-content-right">
                            <span>מורשה 1:</span>
                            {inputsR.map((item, id) =>
                                <div className="input-main" key={id}>
                                    {item.crb_5_right_input_select === 'date' ? 
                                     <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)} 
                                        className="content-input"
                                    />
                                    : 
                                    <input 
                                    ref={el => inputsRef.current[id] = el} 
                                    placeholder=""
                                    type="text"  
                                    name={item.crb_5_right_input}  className="content-input s5-input" 
                                    required 
                                    onChange={(e) => changeHandler(e, item.crb_5_right_input_required, item.crb_5_right_input_select, 'מורשה 1:', 0)}
                                    maxlength={ 
                                            item.crb_5_right_input_select === 'phone' ? 10 : 
                                            item.crb_5_right_input_select === 'company' && 5 }
                                    data-required={ item.crb_5_right_input_required  }
                                    data-type={item.crb_5_right_input_select}
                                     />}
                                    {item.crb_5_right_input_select !== 'date' &&
                                    <span className="input-placeholder" onClick={() => inputsRef.current[id].focus()}>{item.crb_5_right_input}</span>}
                                </div>
                            )}
                                
                        </div>
                        <div className="section-3-content-left">
                            <span>מורשה 2:</span>
                            {inputsL.map((item, id) => 
                                <div className="input-main" key={id}>
                                    {item.crb_5_left_input_select === 'date' ? 
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)} 
                                        className="content-input"
                                    />
                                    : 
                                    <input 
                                    ref={el => inputsRef.current[id+10] = el} 
                                    placeholder="" 
                                    type="text" 
                                    name={item.crb_5_left_input} 
                                    className="content-input s5-input" 
                                    data-required={ item.crb_5_left_input_required  }
                                    data-type={ item.crb_5_left_input_select }
                                    required 
                                    maxlength={ 
                                            item.crb_5_left_input_select === 'phone' ? 10 : 
                                            item.crb_5_left_input_select === 'company' && 5 }
                                    onChange={(e) => changeHandler(e, item.crb_5_left_input_required, item.crb_5_left_input_select, 'מורשה 2:', 1)} />}
                                    {item.crb_5_left_input_select !== 'date' &&
                                    <span className="input-placeholder" onClick={() => inputsRef.current[id+10].focus()}>{item.crb_5_left_input}</span>}
                                </div>  
                            )}
                                   
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}