import React, { useEffect, useState } from 'react'
import { useCleanup } from '../hooks/cleanup.hook.js'


export const CardType = React.forwardRef(({ vouchers, appSettings, setAppSettings, scrollTo, getVouchers }, ref) => {

    const [ cardTypeImg, setCardTypeImg ] = useState()

    const { cleanSettings } = useCleanup()

    const typeHandler = (e, type) => {
        e.preventDefault()

        let st = appSettings.steps
        let pf = appSettings.preFade
        st = {...st, st4 : true, st5 : false, st6 : false, st7 : false, st8 : false, st9 : false, st10 : false }
        pf = {...pf, st3 : false, st4 : true, st5 : true, st6 : true, st7 : true, st8 : true, st9 : true, st10 : true }
        // st = {...st, st4 : true, st5 : false, st6 : false, st7 : false, st8 : false, st9 : false, st10 : false }
        // pf = {...pf, st3 : false, st4 : false, st5 : true, st6 : true, st7 : true, st8 : true, st9 : true, st10 : true }
        setAppSettings({
            ...appSettings, 
            cardType : type, 
            steps : st, 
            preFade : pf,
            deliveryPay : 0,
            toPayDiscount : 0,
            toPay : 0,
            paymentType : ''
            })
        scrollTo( 2 )
    }
    
    useEffect(() => {
        const key = appSettings.currentVoucherKey
        setCardTypeImg(vouchers[key].crb_card_img1)
    }, [appSettings.currentVoucherKey])

    const classes = {
        cl : appSettings.preFade.st2 ? 'section-2 pre-fade' : 'section-2'
    }


    
    return(
        <section className={classes.cl} id="step2" ref={ref}>
            <div className="wrapper">
                <div className="section-title">
                    <div><span>2</span></div>
                    <p>בחירת תו מגנטי/ דיגיטלי</p>
                </div>
                <div className="section-2-content">
                    <div className="section-2-content-right">
                        <img className="section-2-content-img" src={cardTypeImg} alt="" />
                    </div>
                    <div className="section-2-content-left">
                        <a className={appSettings.cardType === 'plastic' ? 'card-type-select btn not-filled active-card-type' : 'card-type-select btn not-filled'} onClick={(e) => typeHandler(e, 'plastic')} style={{ cursor : 'pointer' }} >תווי שי מגנטיים</a>
                        <a className={appSettings.cardType === 'digital' ? 'card-type-select btn not-filled active-card-type' : 'card-type-select btn not-filled'} onClick={(e) => typeHandler(e, 'digital')} style={{ cursor : 'pointer' }} >תווי שי דיגיטליים</a>
                    </div>
                </div>
            </div>
        </section>
    )
})